<template>
  <div class="details">
    <van-nav-bar title="详情" />
    <!-- 用户发布的内容 -->
    <div class="user-poster" id="user-poster">
      <div class="user-root">
        <!-- 用户头像和id -->
        <div class="header">
          <div class="user-head">
            <router-link :to="'/personDetail/' + posterInfo.userId">
              <img :src="posterInfo.avatar" alt="" />
            </router-link>
          </div>

          <div class="user-name">
            <div class="user-id">{{ posterInfo.nickname }}</div>
            <div class="user-time">{{ posterInfo.created }}</div>
          </div>
        </div>
        <!-- 用书输入的内容 -->

        <div class="user-content">
          <span
            @click.stop="goSearch(posterInfo.difficulty)"
            style="color: dodgerblue"
            >#{{ posterInfo.difficulty }}
          </span>
          <span
            @click.stop="goSearch(posterInfo.location)"
            style="color: dodgerblue; margin-left: 8px"
          >
            #{{ posterInfo.location }}</span
          >
        </div>

        <div class="user-content">
          {{ posterInfo.content }}
        </div>
        <!-- 用户标记路线的图片 -->
        <div class="user-photo">
          <img :src="posterInfo.pic" alt="" @click="getImage()" />
        </div>
      </div>
    </div>

    <!-- 其他用户的评论 -->
    <div class="comments">
      <!-- <h3 style="margin-left:30px">评论</h3> -->
      <van-list
        :immediate-check="false"
        v-model="loading"
        :finished="finished"
        finished-text="快来添加一条评论吧~"
        @load="onLoad"
      >
        <van-cell v-for="(comment, index) in list" :key="index">
          <div class="comment-root">
            <!-- 用户头像和id -->
            <div class="commentHeader">
              <div class="comment-head">
                <a href="#">
                  <img :src="comment.author.avatar" alt="" />
                </a>
              </div>

              <div class="comment-name">
                <span class="comment-id">{{ comment.author.nickname }}</span>
                <span class="comment-time">{{ comment.createDate }}</span>
              </div>
            </div>
            <!-- 用书输入的内容 -->
            <div class="comment-content">
              {{ comment.content }}
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>

    <!-- 底部评论点赞收藏 -->
    <div class="actionContainer">
        
      <div class="user-action">
        <div style="display: flex">
          <input
            @click="goLogin"
            v-model="value"
            @keyup.enter="onSubmit"
            placeholder="说点什么吧~"
          />

          <div class="icons">
            <van-icon
              @click="CollectRoute"
              v-show="!isCollect"
              name="star-o"
              size="25"
            />
            <van-icon
              @click="unCollectRoute"
              v-show="isCollect"
              color="#FFD700"
              name="star"
              size="25"
            />
            <div v-show="posterInfo.collectCounts">
              {{ posterInfo.collectCounts }}
            </div>

            <van-icon style="margin-top: 4px" name="comment-o" size="25" />
            <div v-show="posterInfo.commentCounts">
              {{ posterInfo.commentCounts }}
            </div>

            <van-icon
              @click="LikeRoute"
              v-show="!isLike"
              name="good-job-o"
              size="25"
            />
            <van-icon
              @click="unLikeRoute"
              v-show="isLike"
              color="	#e93855"
              name="good-job"
              size="25"
            />
            <div v-show="posterInfo.likesCounts">
              {{ posterInfo.likesCounts }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ImagePreview } from "vant";
import { Dialog } from "vant";
import Header from "../components/Header/index.vue";
export default {
  name: "Main",
  data() {
    return {
      isLike: false,
      isCollect: false,
      loading: false,
      finished: false,
      upLoadings: {},
      posterInfo: [],
      comments: [],
      list: [],
      value: "",
      k: 0,
      t: 4,
    };
  },

  components: {
    Header,
  },

  methods: {
    goLogin() {
      if (!this.$store.getters.getUser.id) {
        Dialog.confirm({
          confirmButtonColor: "#0099FF",
          cancelButtonColor: "#0099FF",
          confirmButtonText: "前去登录",
          message: "您还没有权限哦，请先登录!",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
    },

    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        if (this.comments.length < this.t) {
          this.list = this.comments;
        } else {
          for (let i = this.k; i < this.k + 4; i++) {
            this.list.push(this.comments[i]);
          }
          this.k = this.k + 4;
          this.t = this.t + 4;
        }

        this.loading = false;
        if (this.list.length >= this.comments.length) {
          this.finished = true;
        }
      }, 1000);
    },

    goSearch(item) {
      this.$router.push({
        name: "search",
        params: {
          value: item,
          show: false,
        },
      });
    },

    onClickLeft() {
      this.$router.push("/main");
    },
    getImage() {
      ImagePreview([this.posterInfo.pic]);
    },

    post(i) {
      const _this = this;
      _this.$axios.get("/blog/" + i).then((res) => {
        _this.posterInfo = res.data.data;
        this.getComments(_this.posterInfo.id);
      });
    },

    getComments(id) {
      const that = this;
      that.$axios.get("/comment/article/" + id).then((res) => {
        that.comments = res.data.data;
        that.comments.reverse();
        this.onLoad();
      });
    },

    onSubmit() {
      const a = this.value.replace(/\s/g, "");
      if (a == "") {
      } else {
        this.$axios
          .post(
            "/comment/create/change",
            {
              content: this.value,
              articleId: this.posterInfo.id,
              authorId: this.$store.getters.getUser.id,
              parentId: 0,
              toUid: this.posterInfo.userId,
            },
            {
              headers: { Authorization: localStorage.getItem("token") },
            }
          )
          .then((res) => {
            Toast("评论成功(‾◡◝)");
            this.commentsCount(this.$route.params.posterId);
          });
      }

      this.value = "";
    },

    commentsCount(id) {
      this.posterInfo.commentCounts = this.posterInfo.commentCounts + 1;
      this.$axios
        .post("/blog/editComments", {
          id: id,
          commentCounts: this.posterInfo.commentCounts,
          collectCounts: this.posterInfo.collectCounts,
          likeCounts: this.posterInfo.likesCounts,
        })
        .then((res) => {
          this.post(this.$route.params.posterId);
        });
    },

    collectCount() {
      this.posterInfo.collectCounts = this.posterInfo.collectCounts + 1;
      this.$axios.post("/blog/editComments", {
        id: this.posterInfo.id,
        commentCounts: this.posterInfo.commentCounts,
        collectCounts: this.posterInfo.collectCounts,
        likeCounts: this.posterInfo.likesCounts,
      });
    },

    CollectRoute() {
      if (!this.$store.getters.getUser.id) {
        Dialog.confirm({
          confirmButtonColor: "#0099FF",
          cancelButtonColor: "#0099FF",
          confirmButtonText: "前去登录",
          message: "您还没有权限哦，请先登录!",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      } else {
        this.isCollect = !this.isCollect;
        this.$axios
          .post(
            "/collection/addCollect",
            {
              articleId: this.posterInfo.id,
              userId: this.$store.getters.getUser.id,
            },
            {
              headers: { Authorization: localStorage.getItem("token") },
            }
          )
          .then((res) => {
            Toast("收藏成功！");
            this.collectCount();
          });
      }
    },

    unCollectCount() {
      this.posterInfo.collectCounts = this.posterInfo.collectCounts - 1;
      this.$axios.post("/blog/editComments", {
        id: this.posterInfo.id,
        commentCounts: this.posterInfo.commentCounts,
        collectCounts: this.posterInfo.collectCounts,
        likeCounts: this.posterInfo.likesCounts,
      });
    },

    unCollectRoute() {
      this.isCollect = !this.isCollect;
      this.$axios
        .post("/collection/cancelCollect", {
          articleId: this.posterInfo.id,
          userId: this.$store.getters.getUser.id,
        })
        .then((res) => {
          this.unCollectCount(this.$route.params.posterId);
          Toast("已取消");
        });
    },

    likeCount() {
      this.posterInfo.likesCounts = this.posterInfo.likesCounts + 1;
      this.$axios
        .post("/blog/editComments", {
          id: this.posterInfo.id,
          commentCounts: this.posterInfo.commentCounts,
          collectCounts: this.posterInfo.collectCounts,
          likesCounts: this.posterInfo.likesCounts,
        })
        .then((res) => {
          console.log("点赞完后的posterinfo", this.posterInfo);
        });
    },

    LikeRoute() {
      if (!this.$store.getters.getUser.id) {
        Dialog.confirm({
          confirmButtonColor: "#0099FF",
          cancelButtonColor: "#0099FF",
          confirmButtonText: "前去登录",
          message: "您还没有权限哦，请先登录!",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      } else {
        this.isLike = !this.isLike;
        this.$axios
          .post(
            "/like/addLike",
            {
              articleId: this.posterInfo.id,
              userId: this.$store.getters.getUser.id,
            },
            {
              headers: { Authorization: localStorage.getItem("token") },
            }
          )
          .then((res) => {
            this.likeCount();
            Toast("已点赞");
          });
      }
    },

    unLikeRoute() {
      this.isLike = !this.isLike;
      this.$axios
        .post("/like/cancelLike", {
          articleId: this.posterInfo.id,
          userId: this.$store.getters.getUser.id,
        })
        .then((res) => {
          this.unLikeCount();
          Toast("已取消");
        });
    },

    unLikeCount() {
      this.posterInfo.likesCounts = this.posterInfo.likesCounts - 1;
      this.$axios
        .post("/blog/editComments", {
          id: this.posterInfo.id,
          commentCounts: this.posterInfo.commentCounts,
          collectCounts: this.posterInfo.collectCounts,
          likesCounts: this.posterInfo.likesCounts,
        })
        .then((res) => {
          console.log("取消赞完后的posterinfo", this.posterInfo);
        });
    },

    isLiked(user_id) {
      this.$axios.get("/like/likes/" + user_id).then((res) => {
        const likes = res.data.data;
        likes.forEach((element) => {
          if (this.$route.params.posterId == element.articleId) {
            this.isLike = true;
          }
        });
      });
    },

    isCollection(user_id) {
      this.$axios.get("/collection/collects/" + user_id).then((res) => {
        const collections = res.data.data;
        collections.forEach((element) => {
          if (this.$route.params.posterId == element.articleId) {
            this.isCollect = true;
          }
        });
      });
    },
  },

  computed: {},

  mounted() {
    if (this.$store.getters.getUser.id != null) {
      this.isLiked(this.$store.getters.getUser.id);
      this.isCollection(this.$store.getters.getUser.id);
    }

    this.post(this.$route.params.posterId);
  },
};
</script>
  
<style scoped>
.details {
  width: 100%;
  /* position: relative; */
}

.user-poster {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: white;
  color: black;
}

.user-root {
  /* border-radius: 25px; */
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  margin: 0 auto;
  width: 95%;
  height: 50px;
  padding: 15px;
}

.user-head {
  width: 50px;
  height: 50px;
}

.user-head img {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.user-name {
  margin-left: 8px;
  color: black;
  padding: 8px;
}
.user-time {
  color: #9d9b9b;
  font-size: 12px;
  margin-top: 5px;
}

.user-id {
  font-size: 15px;
  font-weight: 600;
}

.user-content {
  margin-left: 15px;
  color: black;
  font-size: 15px;
  /* background-color: yellow; */
}

.user-photo {
  display: flex;
  margin: 0 auto;
  padding: 10px;
  width: 95%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(199, 199, 199);
}

.user-photo img {
  border-radius: 10px;
  width: 100%;
}

.icons {
  background: white;
  display: flex;
  right: 0;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.icons i {
  position: relative;
  float: left;
  margin-left: 13px;
  margin-right: 8px;
  width: 28px;
  height: 28px;
}

.comments {
  background-color: white;
  margin-bottom: 80px;
  min-height: 40vh;
}

.comment-root {
  overflow: hidden;
}

.commentHeader {
  display: flex;

  height: 35px;
  margin-bottom: 5px;
}

.comment-head {
  width: 35px;
  height: 35px;
  /* background-color: rgb(68, 138, 138); */
}

.comment-head img {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.comment-name {
  margin-left: 6px;
  color: black;
  display: flex;
  flex-direction: column;
}
.comment-time {
  color: #8e8c8c;
  font-size: 12px;
  margin-left: 10px;
}

.comment-id {
  font-size: 14px;
  color: #393939;
  margin-left: 10px;
}

.van-cell {
  line-height: 18px;
}
.comment-content {
  margin-left: 50px;
  color: black;
  font-size: 13px;
}

.user-action input {
  -webkit-appearance: none;
  background-color: transparent;
  padding-left: 15px;
  background: #f3f3f3;
  border-radius: 25px;
  overflow: hidden;
  border: 0;
  width: 180px;
  height: 35px;
}

/* .actionContainer{
    position: relative; 
    height: auto;
    width: 100%; 
    background-color: rgb(134, 50, 50);
}  */

.user-action {
  max-width: 600px;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  border-top: 1px solid #d2d2d2;
  background: white;
  padding: 12px;
}

</style>